<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
    <v-skeleton-loader v-show="loader" type="table" ></v-skeleton-loader>
    <!-- <v-skeleton-loader v-show="loader" max-width="500" class="mx-auto" type="card"></v-skeleton-loader> -->
    <h1 v-show="!loader" class="titulo">Relatório de Ganhadores</h1>
      <v-row v-show="!loader"  justify="center">
        <v-col cols="12" md="4" sm="4" lg="3" class="center-itens-datas">
          <v-autocomplete
            v-model="selectConcurso"
            :items="listSelectConcursos"
            label="Concursos" />
        </v-col>
        <v-col cols="12" md="4" sm="4" lg="3" class="center-itens-datas">
          <dateTimerPicker
              color="#2196F3"
              prepend-icon="event"
              label="Data de início"
              v-model="form.dtInicial"
              :timePickerProps="{format: '24hr'}"
              date-format="dd/MM/yyyy"
              time-format="HH:mm"
              clearText="Limpar"
              :textFieldProps="{'prepend-icon': 'event'}"
          />
        </v-col>
        <v-col cols="12" md="4" sm="4" lg="3" class="center-itens-datas">
          <dateTimerPicker
              color="#2196F3"
              label="Data Final"
              v-model="form.dtFinal"
              :datePickerProps="{'allowed-dates': allowedDates}"
              :timePickerProps="{format: '24hr', min: minHora}"
              date-format="dd/MM/yyyy"
              time-format="HH:mm"
              clearText="Limpar"
              :textFieldProps="{'prepend-icon': 'event'}"
              @valorDate="compararDataEnd"
          />
        </v-col>
        <v-col  md="3" lg="3" sm="4" class="text-center center-itens-download">
           <!-- Botoes para download -->
          <v-btn class="bottom"  color="info" justify-self="center" @click="buscarRelatorio(true)" outlined>Buscar</v-btn>
          <div @mouseleave="visible = false" class="bottom-mobile">
            <v-btn class="bottom-2" color="info" @mouseover="visible = true"> Baixar </v-btn>
            <v-list :class="[!visible? 'none' : 'visivel']">
              <template v-for="(item, index) in items">
                <v-list-item :key="index" @click="downalod(item.value)">
                  <v-list-item-title>{{ item.title }} </v-list-item-title>
                </v-list-item>
              </template>
                </v-list>
            </div>
        </v-col>

      <v-col v-show="!loader" cols="12" class="conteudo-div">
          <div v-html="dados"></div>
        </v-col>
        <v-col cols="12" class="relatorio">
          <div class="relatorio tex-center">
            <div class="relatorio-pagination">
              <span  @click="incrementPage('-')"> <v-icon>keyboard_arrow_left</v-icon></span>
              <span> Página {{ page  }} de {{ pageMax }} </span>
              <span class="icon-right"  :disabled="page === pageMax" @click="incrementPage('+')">  <v-icon>keyboard_arrow_right</v-icon> </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

// mixins para com funcao padroes na geração de relatorios
import mixinRelatorio from '@/views/relatorios/mixins/relatorio'

export default {
  name: 'RelatorioGanhadoresAdmin',
  mixins: [mixinRelatorio],
  data: () => ({ }),
  computed: {
    ...mapState({ dados: state => state.relatorioGanhadoresAdminPrint.conteudo }),
    ...mapGetters('relatorioGanhadoresAdminPrint', ['listSelectConcursos']),
  },
  mounted () {
    this.getConcursos()
  },
  methods: {
    ...mapActions('relatorioGanhadoresAdminPrint', ['getHtml', 'requestRelatorio', 'getConcursos']),
  }
}
</script>

<style lang="scss" >
$cor: #0091ff;
.relatorio-dre-init{
  .none{
    display: none;
  }
  .conteudo-div{
    padding-top:0 ;
    > table{
      background: white !important;
    }
    @media(max-width: 1320px) {
      table{
        width: 1290px;
      }
      overflow-x: scroll;
    }
  }
  .relatorio {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: 'center';
    justify-content: 'center';
    &-download {
      width: 100%;
      &-icons {
        text-align: right;
        width: 40px;
        color: $cor ;
        background:#fafafa;
        padding: 5px;
        display: inline-block;
        border-radius: 5px;
        margin-left: 5px;
        border: 1px solid $cor;
        font-size: 0.8rem;
        font-weight: 600;
        cursor: pointer;
        &:hover{
          background:$cor ;
          opacity: 0.8;
          color: white;
        }
        &:first-child{
          margin-right: 5px;
        }
      }
    }
    &-pagination{
      position: relative;
      display: flex;
      justify-content: flex-end;
      @media(max-width: 660px) {
        justify-content: center;
      }
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      span {
        display: flex;
        &:last-child {
          cursor: pointer;
        }
        &:nth-child(2) {
          justify-content: center;
          align-items: center;
        }
        &:first-child {
          cursor: pointer;
          justify-content: flex-end;
        }
      }
    }

  }
  .default-colunas {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .center-itens{
    &-download {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    &-datas {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // @extend .default-colunas;
    }
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    @extend .default-colunas;
    > div {
      padding: 0;
    }
  }
  .v-list {
    position: absolute;
    border: 1px solid $cor;
    background: #fafafa;
    z-index: 1;
    width: 72px;
    @media(max-width: 769px){
      right: 25px;
    }
    .v-list-item{
      min-height: 30px;
      &__title{
        font-size: 12px;
        font-weight: 600;
      }
      &:hover  {
        color: $cor !important;
      }
    }
  }
  .bottom{
    margin-right: 5px;
    box-shadow: none !important;
    &-2{
      background-color: transparent !important;
      border: 1px solid $cor;
      color: $cor !important;
      box-shadow: none !important;
    }
    &-mobile {
      @media(max-width: 769px) {
      width: 100%;
      text-align: right;
      }
    }
  }
}
</style>